import Index from './components/Index.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
    name: "LoadPage",
    component: Index,
    path: '/'
}];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
// ViewOtherUser